<template>
    <v-row>
        <v-col>
            <v-fade-transition>
                <v-skeleton-loader
                    :height="height"
                    :max-height="maxHeight"
                    :type="type"
                    :elevation="elevation"
                />
            </v-fade-transition>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Skeleton',
    props: {
        type: {
            type: String,
            default: 'table',
        },
        height: {
            type: String,
            required: false,
        },
        maxHeight: {
            type: String,
            required: false,
        },
        elevation: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getAlert',
        ]),
    },
}
</script>
