<template>
    <div v-if="Object.keys(member).length && member.id">
        <v-card-title>
            <div class="d-flex flex-row align-end">
                <div class="mb-1" v-if="channelLogo && getMemberChannel.hasOwnProperty('ss_id')">
                    <v-card outlined class="pa-3 elevation-0" max-width="100px">
                        <v-img
                            contain
                            :src="require(`../../assets/images/portale/${getMemberChannel.ss_id}.png`)"
                            :lazy-src="require(`../../assets/images/portale/${getMemberChannel.ss_id}.png`)"
                        />
                    </v-card>
                </div>
                <div :class="channelLogo && 'pl-4'">
                    {{ titleTextReplaced }}
                </div>
                <div>
                    <div v-if="!member.feondi_uebertragungabgeschlossen">
                        <v-progress-circular
                            class="ml-2"
                            :size="15"
                            indeterminate
                            :color="getColors.error"
                        />
                        <span class="caption" :style="`color:${getColors.error}`">
                            {{ $t('common.memberHeadingDetails.syncing', { since: getElapsedTimeSinceSyncing(parseInt(member.feondi_deletealltstamp)) }) }}
                        </span>
                    </div>
                    <div v-else>
                        <v-icon class="ml-2" small :color="$store.getters.getColors.success">
                            mdi-check
                        </v-icon>
                        <span class="caption" :style="'color:' + getColors.success"
                        >
                            {{ $t('common.memberHeadingDetails.synced') }}
                        </span>
                    </div>
                </div>
            </div>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between fill-height">
            <div class="pt-1">
                {{ subtitleText }}
            </div>
        </v-card-text>
        <v-divider class="my-3"/>
        <v-card-text>
            <div class="d-flex flex-column">
                <div>{{ getUserFeondiDetails().variantText }}</div>
                <div>{{ getUserFeondiDetails().registeredProductsText }}</div>
                <div>{{ $tc('common.memberHeadingDetails.totalProperties', getUserFeondiDetails().totalPropertiesCount) }}</div>
                <div :style="(getUserFeondiDetails().salesPotential > 10 ? 'color: ' + this.getColors.secondary2 + ';font-weight:bold' : '')">{{ $tc('common.memberHeadingDetails.salesPotential', getUserFeondiDetails().salesPotential) }}</div>
            </div>
        </v-card-text>
    </div>
    <div v-else>
        <skeleton type="article, article" height="320px"/>
    </div>
</template>

<script>
import ActAsMemberButton from '@/components/Allgemein/AgiereAlsKundeSchaltflaeche.vue'
import Skeleton from '@/components/Allgemein/Skeleton'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'MemberHeadingDetails',
    components: { Skeleton, ActAsMemberButton },
    props: {
        channelLogo: {
            type: Boolean,
            default: false,
        },
        titleText: {
            type: String,
            required: true,
        },
        subtitleText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            member: {},
        }
    },
    computed: {
        ...mapGetters([
            'getMember',
            'getUser',
            'getActingMemberid',
            'getColors',
            'getMemberChannel',
        ]),
        titleTextReplaced: function () {
            return this.titleText.replace(/MEMBER_FIRSTNAME/g, this.member.firstname).replace(/MEMBER_NAME/g, this.member.name).replace(/MEMBER_ID/g, this.member.id)
        },
    },
    watch: {
        '$store.state.member': {
            deep: true,
            immediate: true,
            handler() {
                this.member = this.getMember
            },
        },
    },
    methods: {
        getUserFeondiDetails() {

            let variantText = ''

            if (this.member.feondi_anzpremium && !this.member.feondi_anzvariantec)
                variantText = `Feondi-${ this.$i18n.t('common.words.variant') } B`
            else if (this.member.feondi_anzpremium && this.member.feondi_anzvariantec)
                variantText = `Feondi-${ this.$i18n.t('common.words.variant') } B/C`
            else if (!this.member.feondi_anzpremium && this.member.member_anzobjekte && this.member.feondi_anzvariantec)
                variantText = `Feondi-${ this.$i18n.t('common.words.variant') } A/C`
            else if (!this.member.feondi_anzpremium && this.member.member_anzobjekte && !this.member.feondi_anzvariantec)
                variantText = `Feondi-${ this.$i18n.t('common.words.variant') } A`
            else
                variantText = 'No Feondi'

            let registeredPropertiesCount = 0
            let registeredProductsText = ''

            if (this.member.feondi_anzpremium && !this.member.feondi_anzvariantec) {
                registeredPropertiesCount = this.member.feondi_anzpremium
                registeredProductsText = `${ registeredPropertiesCount } ${ this.$i18n.t('common.memberHeadingDetails.registeredProperties') }`
            } else if (this.member.feondi_anzpremium && this.member.feondi_anzvariantec) {
                registeredPropertiesCount = this.member.feondi_anzpremium
                registeredProductsText = `B: ${ this.member.feondi_anzpremium } | C: ${ this.member.feondi_anzvariantec } ${ this.$i18n.t('common.memberHeadingDetails.registeredProperties') } | ${ this.member.feondi_icssyncintervall } ${ this.$i18n.t('common.memberHeadingDetails.icsInterval') }`
            } else if (!this.member.feondi_anzpremium && this.member.feondi_anzvariantec) {
                registeredPropertiesCount = this.member.member_anzobjekte
                registeredProductsText = 'A: ' + this.member.member_anzobjekte + ' | C: ' + this.member.feondi_anzvariantec + ' registered properites | ' + this.member.feondi_icssyncintervall + ' ICS-Syncs/24h'
            } else if (!this.member.feondi_anzpremium && this.member.member_anzobjekte && !this.member.feondi_anzvariantec) {
                registeredPropertiesCount = this.member.member_anzobjekte
                registeredProductsText = `${ this.member.member_anzobjekte } ${ this.$i18n.t('common.memberHeadingDetails.registeredProperties') }`
            }

            return {
                variantText: variantText,
                registeredPropertiesCount: registeredPropertiesCount,
                registeredProductsText: registeredProductsText,
                totalPropertiesCount: this.member.member_totalproperties,
                salesPotential: this.member.member_totalproperties - registeredPropertiesCount,
            }
        },
        getElapsedTimeSinceSyncing(timestamp) {
            if (timestamp)
                return `since ${ moment.unix(timestamp).fromNow() }`
        },
    },
    created() {
        this.member = this.getMember
    },
}
</script>
